import request from './request'
import { QueryPageDto, WorkerDto } from './dto'

// 案件列表
export function getReportLlist (
    params: QueryPageDto
): Promise<{ data: WorkerDto[]; total: number }> {
    return request.get('/report/list', {
        params
    })
}

export function getPolicyPersonList (
    params: any
): Promise<{ data: WorkerDto[]; total: number }> {
    return request.get('/policy.person/list', {
        params
    })
}

interface addReportReqDto {
  employeeId: string
  employeePhone: string
  workCompany: string
  caseDate: string
  type: string
  address: string
  desc: string
  fileIds: string[]
  reporterPhone: string
}

export function addReport (data: addReportReqDto): Promise<void> {
    return request.post('/report/add', data)
}

// 撤销报案
export function cancelReport (data: { reportId: string }): Promise<void> {
    return request.post('/report/cancel', data)
}

// 案件详情
export interface ReportDetailDto {
  address: string
  hospital: string
  work_company: string
  case_date: string
  create_time: string
  bill: {
    id: number
    series: string
    type: string
    url: string
  }[]
  desc: string
  employee: { employee_id: number; name: string; idcard: string }
  employee_id: number
  employee_phone: string
  estimate_money: string
  final_money: string
  is_serious: number
  money_shangcan: string
  money_wugong: string
  money_yiliao: string
  policyPerson: {
    occupation_name: string
    occupation_level: string
    start_date: string
    policy: {
      plan: {
        name: string
      }
    }
    company_name: string
  }
  file: {
    url: string
  }[]
  policy_person_id: number
  remark: string
  report_id: number
  reporter_phone: string
  status: string
  trace: {
    trace_date: string
    title: string
    content: string
  }[]
  type: string
}

export function getReportDetail (params: {
  reportId: string
}): Promise<ReportDetailDto> {
    return request.get('/report/detail', {
        params
    })
}

export interface FileDto {
  file_name?: string
  external_url?: string
  preview_url?: string
  file_id?: string
}

export interface BillDto {
  name: string,
  is_required: boolean,
  example: string,
  get_place: string,
}

export interface BillSetDto {
  name: string
  value: number
  bills: BillDto[]
}

export interface UpdateBillDto {
  reportId: number
  fileId: string
  series: number,
  type: string
}

// 获取单据上传的配置项
export function getBillSet (): Promise<BillSetDto[]> {
    return request.get('/report/billConfig')
}

// 获取单据上传的配置项
export function updateBill (params: UpdateBillDto): Promise<BillSetDto> {
    return request.post('/report/addBill', params)
}

// 获取单据上传的配置项
export function delBill (params: { reportId: number; id: number }): Promise<BillSetDto> {
    return request.post('/report/removeBill', params)
}
